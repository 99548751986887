@import '../../scss/variables';

.booking {
    background: #fbfbfb;
    padding-bottom: 40px;
    &-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 188px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 69px;
    }
    &-title {
        font-family: Montserrat-bold;
        font-size: 30px;
        letter-spacing: 1.29px;
        text-align: center;
        color: #333333;
    }
    &-subtitle {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
    }
    &-header {
        margin-top: 30px;
        margin-bottom: 40px;
        .controls {
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
        }
        
        .step {
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #9c9c9c;
            pointer-events: none;
        }

        .step-current {
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: $secondary-color;
            text-transform: uppercase;
        }
    }
    &-view {
        max-width: 888px;
        width: 100%;
        button {
            border-radius: 8px;
            border: solid 2px #5b5b5b;
            background-color: #ffffff;
            font-family: Montserrat-Regular;          
            font-size: 14px;
            letter-spacing: 0.1px;
            color: #333333;
            &:hover {
                background-color: #333333;
                color: #ffffff;
                font-family: Montserrat-Bold;
            }
        }
    }
    
    .grid_1 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 45px; 
        column-gap: 20px;
        row-gap: 15px;
        margin-top: 20px;
        margin-bottom: 34px;
    }
    .grid_2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 59px; 
        column-gap: 20px;
        row-gap: 15px;
        margin-top: 20px;
        margin-bottom: 34px;
    }
}